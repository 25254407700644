<template lang="html" src="./pageSystem.template.vue"></template>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSystem.i18n');

export default {
  name: 'PageSystem',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      tabs: [
        {
          name: this.$t('Monitoring'),
          route: { name: 'SystemMonitoring' },
          icon: 'fas fa-satellite-dish',
        },
        {
          name: this.$t('Backups'),
          route: { name: 'SystemBackups' },
          icon: 'fas fa-shield-alt',
        },
        {
          name: this.$t('AdminUsers'),
          route: { name: 'SystemAdminUsers' },
          icon: 'fas fa-user-shield',
        },
        {
          name: this.$t('DocumentConfigurations'),
          route: { name: 'SystemDocumentConfigurations' },
          icon: 'fas fa-file-alt',
        },
      ],
    };
  },
  updated() {
    this.init();
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if(this.$route.name === 'System') {
        this.$router.push({name: 'SystemMonitoring'});
      }
    }
  },
};
</script>
